<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight">
        <div class="TrueTopicCenter">
          <div class="TrueTopicCenter-Title">近期答题报告</div>
          <div class="TrueTopicCenter-item">
            <dl>
              <dd class="on">2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
              <dd>2022/02/02</dd>
            </dl>
          </div>
          <div class="TrueTopicCenterTitle">2019年度真题</div>
          <div class="TrueTopicCenter-tItem">
            <div class="TrueTopicCenter-time">
              倒计时：<span>1小时20分</span>
            </div>
          </div>
          <ul>
            <li>
              <div class="FontTitle">选择题</div>
              <div class="TrueTopicCenter-Font">
                在GDP的几大部分构成中,不包括
              </div>
              <div class="TrueTopicCenter-or">
                <dl>
                  <dd>
                    <div class="TrueTopicCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TrueTopicCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TrueTopicCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TrueTopicCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TrueTopicCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TrueTopicCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TrueTopicCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TrueTopicCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <div class="FontTitle">名词解释题</div>
              <div class="TrueTopicCenter-Font">什么是贷款？</div>
              <div class="TrueTopicCenter-text">
                <textarea></textarea>
              </div>
            </li>
          </ul>

          <div class="TrueTopicBut">
            <button class="but2" type="button">提交</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show: false,
      info: "对",
    };
  },
  methods: {
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .TrueTopicCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      .FontTitle {
        font-weight: bold;
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      .TrueTopicCenterTitle {
        font-size: 36px;
        font-weight: 400;
        color: #060606;
        line-height: 60px;
        margin-top: 10px;
      }
      .TrueTopicCenter-Title {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
      }
      .TrueTopicCenter-tItem {
        margin-top: 10px;
        height: 40px;
        .TrueTopicCenter-topic {
          display: block;
          height: 40px;
          background: #636363;
          border: 1px solid #707070;
          border-radius: 20px;
          line-height: 40px;
          padding: 0 25px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          float: left;
        }
        .TrueTopicCenter-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .page-center {
        height: 32px;
        background: url("~@/assets/TrueTopic/itembg.png") repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url("~@/assets/TrueTopic/left.png") no-repeat center;

            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url("~@/assets/TrueTopic/right.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .TrueTopicCenter-Font {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .TrueTopicCenter-or {
        margin-top: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .TrueTopicCenter-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .TrueTopicCenter-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .TrueTopicCenter-text {
        margin-top: 20px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.TrueTopicBut {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;
    font-size: 16px;
    border-radius: 8px;
    padding: 0 30px;
    margin: 0 10px;
    cursor: pointer;
    color: #333333;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #ffcf00;
    }
  }
}

.tmItem li::after {
  clear: both;
  display: block;
  content: "";
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.TrueTopicCenter-item {
  margin-top: 10px;
  dl {
    dd {
      float: left;
      margin-right: 10px;
      height: 30px;
      background: #173d7c;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      padding: 0 20px;
      color: #ffffff;
      cursor: pointer;
      &.on {
        background-color: #ffcf00;
        color: #434343;
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}
</style>
